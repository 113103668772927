@import "./Themeplatte/themeplatte.scss";

* {
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

html {
  font-size: 10px;
}

.color {
  color: red;
  font-size: smaller;
}

#checkoutmodal .merchant-detail-box.reference-details .merchant-logo{
  border-radius: 50% !important;
}

body {
  font-size: 1.6rem;
  font-family: "Montserrat", sans-serif !important;
  background-color: var(--bodybg);
}

a {
  color: inherit;
  text-decoration: none !important;
}

.container {
  max-width: 1720px;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;

  @media screen and (max-width: 767px) {
    padding: 0 1rem;
  }
}

.wp_button {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  display: block;
  position: fixed;
  right: 3rem;
  z-index: 100;
  bottom: 4rem;

  img {
    height: 100%;
    width: 100%;
  }
}

.MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: var(--disabled-text) !important;
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}

.MuiButtonBase-root.Mui-disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

// ---------- Common sss
.text-primary {
  color: var(--primarytext);
}

.text-grey {
  color: var(--greytext);
}

.text-dark {
  color: var(--darktext);
}

.text-white {
  color: var(--whitetext);
}

.bg-primary {
  background: var(--primarybg);
}

.bg-primary-light {
  background: var(--primarybglight);
}

section {
  margin-bottom: 4rem;
}

p.list {
  padding-left: 1.5rem;
  position: relative;
  margin-top: 1rem;

  &::after {
    content: " ";
    position: absolute;
    top: 0.8rem;
    left: 0;
    height: 0.6rem;
    width: 0.6rem;
    border-radius: 50%;
    background-color: var(--greytext);
  }
}

// -------------- Common Border
.border-gradient {
  // border: 1px solid transparent;
  border: 1px solid rgba(10, 83, 181, 0.3);
  background: transparent;
  background-clip: padding-box;
  position: relative !important;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: -1px;
  //   bottom: -1px;
  //   left: -1px;
  //   right: -1px;
  //   background: linear-gradient(to top right,
  //       rgba(22, 107, 221, 0),
  //       rgba(0, 61, 143, 0.5),
  //       rgba(22, 107, 221, 0),
  //       rgba(0, 61, 143, 0.5));
  //   z-index: -1;
  //   border-radius: 1.8rem;
  // }
}

// -------------- Common Button
button.btn-ta,
a.btn-ta {
  padding: 0.8rem 2.4rem;
  border-radius: 5rem;
  font-size: 1.5rem;
  display: block;
  text-transform: capitalize;

  &.square {
    min-height: 4rem;
    max-height: 4rem;
    min-width: 4rem;
    max-width: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.2rem;

    @media screen and (max-width: 1199px) {
      min-height: 4rem;
      max-height: 4rem;
      min-width: 4rem;
      max-width: 4rem;
      padding: 0.8rem;
    }
  }

  &.filled {
    background: var(--primarybg);
    color: var(--whitetext);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    cursor: not-allowed;
    background: var(--primarydisabled);
    color: var(--whitetext);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .signature {
    width: 100px;
    height: 250px;
  }

  &.outlined {
    border: 1px solid #003d8f;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primarytext);
  }

  img,
  svg {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 1199px) {
    padding: 0.8rem 1.4rem;
  }

  @media screen and (max-width: 767px) {
    font-size: 1.3rem;
  }
}

// ------------- Select & input css
.form_control {
  &.mrt-20 {
    margin-top: 2rem;
  }

  label.label {
    font-size: 1.6rem;
    color: var(--greytext);
    margin-bottom: 0.6rem;
    display: block;

    &.required {
      position: relative;

      &::after {
        content: "*";
        position: absolute;
        top: 0;
        right: auto;
        margin-left: 1rem;
        color: #d30000;
        font-size: 1.8rem;
        font-weight: 600;
      }
    }
  }

  div.MuiInputBase-root {
    width: 100%;

    input.MuiInputBase-input,
    textarea.MuiInputBase-input {
      width: 100%;
      height: auto;
      border-radius: 0.6rem;
      background-color: var(--inputbg) !important;
      padding: 1rem;
      font-size: 1.6rem;
      color: var(--colortitle);
      border: 1px solid var(--inputborder);

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }

      &::placeholder {
        color: var(--greytext);
      }
    }
  }

  .dropdown-ta {
    width: 100%;
    border-radius: 0.6rem;
    border: 1px solid var(--inputborder);
    background-color: var(--inputbg);

    &.small {
      max-width: 8rem;
      width: 100%;
    }

    .MuiSelect-select {
      padding: 0.9rem;
      font-size: 1.4rem;
      color: var(--colortitle);
      display: flex;
      align-items: center;
    }

    input.MuiSelect-nativeInput {
      top: 0;
      color: var(--colortitle);
      font-size: 1.4rem;
    }

    svg.MuiSelect-icon {
      color: var(--colortitle);
      font-size: 2.4rem;
      right: 3px;
    }
  }

  .datePicker {
    width: 100%;

    button.MuiButtonBase-root {
      padding: 1rem;
      border-radius: 0.8rem;
      font-size: 1.5rem;
      height: 4rem;
      width: 4rem;
      min-width: 4rem;
      display: block;
      text-transform: capitalize;
      background: var(--primarybg);
      color: var(--whitetext);
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 2.2rem;
      }
    }
  }

  &.checkBox {
    span.MuiCheckbox-root {
      padding: 0.5rem;

      svg.MuiSvgIcon-root {
        font-size: 2.6rem;
      }

      &.Mui-checked {
        color: var(--primarytext);
      }
    }

    .MuiTypography-root {
      font-size: 1.3rem;
      display: flex;
      align-items: center;
      color: var(--colortitle);

      span.MuiTypography-root {
        font-size: 1.3rem;
        color: var(--primarytext);
        margin-left: 0.5rem;
      }
    }
  }
}

.MuiPaper-root {
  background-color: var(--inputbg) !important;
  border: 1px solid var(--inputborder);
  box-shadow: none;
  max-height: 34rem !important;
  margin-top: 1rem;
  border-radius: 2rem;

  .MuiMenu-list {
    color: var(--colortitle);

    .MuiButtonBase-root {
      font-size: 1.4rem !important;
    }
  }

  .MuiTypography-caption,
  .MuiPickersDay-root,
  .MuiPickersCalendarHeader-label,
  .MuiSvgIcon-root,
  .MuiPickersYear-yearButton {
    color: var(--colortitle);
    font-size: 1.4rem;
  }

  .MuiYearCalendar-root {
    max-height: 24rem;
  }
}

// --------------------- Heading
h3.sec_title {
  font-size: 3.4rem;
  color: var(--colortitle);
  position: relative;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  font-weight: 600;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("./images/sec-border.png");
    background-size: 100%;
    width: 100%;
    height: 0.6rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.notAfter {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  &.star {
    position: relative;
    padding-left: 2rem;
    font-weight: 500;

    &::before {
      content: "*";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 2.6rem;
      color: var(--primarytext);
      font-weight: 600;
    }
  }

  &.sec_title_small {
    font-size: 2.4rem;

    span {
      font-size: 2.4rem;
    }
  }

  span {
    font-style: italic;
    font-weight: 600;
    color: var(--primarytext);
    font-size: 3.4rem;

    @media screen and (max-width: 991px) {
      font-size: 2.6rem;
    }
  }

  @media screen and (max-width: 991px) {
    font-size: 2.6rem;
  }
}

p.common_text {
  font-size: 1.6rem;
  color: var(--greytext);
  margin-bottom: 2rem;

  @media screen and (max-width: 991px) {
    font-size: 1.3rem;
  }
}

//------heading
h4.sec_title_ {
  font-size: 2.5rem;
  color: var(--colortitle);
  position: relative;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  font-weight: 500;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background-image: url("./images/sec-border.png");
    background-size: 100%;
    width: 100%;
    height: 0.6rem;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.notAfter {
    padding-bottom: 0;

    &:after {
      display: none;
    }
  }

  &.star {
    position: relative;
    padding-left: 2rem;
    font-weight: 500;

    &::before {
      content: "*";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      font-size: 2.6rem;
      color: var(--primarytext);
      font-weight: 600;
    }
  }

  &.sec_title_small {
    font-size: 2.4rem;

    span {
      font-size: 2.4rem;
    }
  }

  span {
    font-style: italic;
    font-weight: 500;
    color: var(--primarytext);
    font-size: 2.5rem;

    @media screen and (max-width: 991px) {
      font-size: 2.6rem;
    }
  }

  @media screen and (max-width: 991px) {
    font-size: 2.6rem;
  }
}

p.common_text {
  font-size: 1.6rem;
  color: var(--greytext);
  margin-bottom: 2rem;

  @media screen and (max-width: 991px) {
    font-size: 1.3rem;
  }
}

// --------------------- Spacing
.mr-top {
  margin-top: 4rem !important;
}

// --------------------- Header
nav.navigation {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  z-index: 50;
  padding: 0.5rem 1rem;
  transition: 0.3s;

  &.sticky {
    background-color: var(--bodybg);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: fixed;
    top: 0;

    .nav_link.active {
      border-color: var(--darktext) !important;
      color: var(--darktext) !important;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a.logo {
      display: block;
      height: 5rem;
      width: 14rem;

      img {
        height: 100%;
        width: 100%;
      }

      @media screen and (max-width: 1199px) {
        height: 3.5rem;
        width: 11rem;
      }
    }

    div.nav_menu {
      background-color: rgba($color: #ffffff, $alpha: 10%);
      padding: 0.5rem;
      border-radius: 5rem;
      height: auto;

      ul {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &.nav_buttons_mob {
          display: none;

          &.bottom {
            margin-top: auto;
            border-top: 1px solid var(--inputborder);
          }

          &.closeSideBar {
            position: absolute;
            top: 0;
            width: 100%;
            // right: 1rem;

            li {
              display: flex;
              align-items: center;
              justify-content: space-between;
              // padding-bottom: 1rem;
              padding: 0px 10px 1rem 1rem ;
              border-bottom: 1px solid var(--inputborder);
            }
          }

          li {
            label.lightDark_mode {
              display: flex;
              align-items: center;
              justify-content: space-between;

              span.MuiFormControlLabel-label {
                font-size: 1.4rem;
                color: var(--greytext);
              }
            }
          }

          @media screen and (max-width: 991px) {
            display: block;
          }
        }

        li {
          margin-right: 1.5rem;
          display: block;

          &:last-child {
            margin-right: 0;
          }

          &:last-child,
          &:nth-last-child(2) {
            display: none;

            @media screen and (max-width: 991px) {
              display: block;
            }
          }

          .nav_link {
            font-size: 1.4rem;
            font-weight: 500;
            padding: 0.6rem 3rem;
            border-radius: 5rem;
            color: var(--greytext);
            text-transform: uppercase;
            display: block;

            &.active {
              border: 1px solid var(--whitetext);
              background-color: rgba($color: var(--whitetext), $alpha: 5%);
              color: var(--whitetext);

              @media screen and (max-width: 991px) {
                background: var(--primarybg);
              }
            }

            @media screen and (max-width: 1199px) {
              font-size: 1.4rem;
              padding: 0.8rem 2rem;
            }

            @media screen and (max-width: 991px) {
              border: none !important;
            }
          }

          @media screen and (max-width: 991px) {
            margin-top: 1.5rem;
            margin-right: 0;
          }
        }

        @media screen and (max-width: 991px) {
          display: block;

          &.nav_menu_ul {
            margin-top: 6rem;
          }
        }
      }

      @media screen and (max-width: 991px) {
        position: fixed;
        top: 0;
        left: -100%;
        display: block;
        max-width: 30rem;
        width: 100%;
        height: 100vh;
        padding: 2rem 1rem;
        border-radius: 0 2rem 2rem 0;
        background-color: var(--bodybg);
        z-index: 9999;
        transition: 0.5s;
        overflow-y: auto;
        overflow-x: hidden;
        display: grid;
        border: 1px solid var(--inputborder);

        &.open {
          left: 0;
        }

        li {
          margin-right: 0;
        }
      }
    }

    ul.nav_buttons {
      display: inline-flex;
      align-items: center;
      justify-content: right;
      padding: 0.5rem;
      border-radius: 5rem;
      height: auto;

      li {
        margin-left: 1.5rem;
        display: block;

        &.humburgar {
          display: none;

          @media screen and (max-width: 991px) {
            display: block;
          }
        }

        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
  }
}

// --------------- Hero Section
section.hero_section {
  height: 80vh;
  width: 100%;
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: var(--herosection);
    z-index: 1;
  }

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    filter: blur(5px);
    z-index: 2;
  }

  &.hero_section_small {
    height: 34rem;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right center;
  }

  .hero_content {
    position: absolute;
    top: 50%;
    left: 10rem;
    transform: translateY(-50%);
    z-index: 3;

    h2 {
      font-size: 4.4rem;
      color: var(--whitetext);
      font-weight: 600;
      display: inline-flex;

      span {
        font-size: 4.4rem;
        font-style: italic;
        color: var(--primarytext);
        margin: 0 0.5rem;
        display: inline-block;

        @media screen and (max-width: 1399px) {
          font-size: 3.4rem;
        }

        @media screen and (max-width: 767px) {
          font-size: 2.2rem;
        }
      }

      @media screen and (max-width: 1399px) {
        font-size: 3.4rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 2.2rem;
      }
    }

    p {
      font-size: 1.6rem;
      font-weight: 400;
      color: var(--whitetext);
      margin-top: 2rem;

      @media screen and (max-width: 1399px) {
        font-size: 1.6rem;
      }

      @media screen and (max-width: 767px) {
        font-size: 1.3rem;
      }
    }

    .hero_contentBtn {
      display: flex;
      align-items: center;
      justify-content: left;
      margin-top: 5rem;

      button.btn-ta {
        margin-right: 1rem;

        img {
          margin-right: 1rem;
        }
      }
    }

    @media screen and (max-width: 991px) {
      left: 5rem;
    }

    @media screen and (max-width: 767px) {
      left: 0;
      padding: 1rem;
    }
  }

  @media screen and (max-width: 991px) {
    height: 70rem;
  }

  @media screen and (max-width: 767px) {
    height: 50rem;
  }
}

// --------------- Deliver Section

.marquee-container {
  height: 35rem;
}

section.deliver_section {
  .autoScroll {
    margin-top: 4rem;

    .marquee {
      .child {
        max-width: 52rem;

        @media screen and (max-width: 575px) {
          max-width: 34rem;
        }
      }
    }
  }

  .devliver_box {
    background-color: var(--boxcolor);
    border-radius: 2rem;
    position: relative;
    box-shadow: 0px 4px 30px 0px rgba(61, 61, 61, 0.12);
    margin-right: 4rem;
    height: 22rem;
    text-overflow: clip;
    word-break: break-word;

    .devliver_box_header {
      padding: 1.4rem 2rem;
      background: linear-gradient(135deg,
          rgba(92, 162, 255, 0) 0%,
          rgba(32, 115, 226, 10%) 10%);
      display: flex;
      align-items: center;
      justify-content: left;
      border-radius: 2rem 2rem 0 0;

      .deliver_icon {
        min-height: 6rem;
        max-height: 6rem;
        min-width: 6rem;
        max-width: 6rem;
        border-radius: 50%;
        background: var(--primarybg);
        color: var(--whitetext);
        margin-bottom: -5rem;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 575px) {
          min-height: 4rem;
          max-height: 4rem;
          min-width: 4rem;
          max-width: 4rem;
          padding: 1.5rem;
          margin-bottom: 0;
        }
      }

      h4 {
        color: var(--primarytext);
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 2rem;

        @media screen and (max-width: 575px) {
          font-size: 1.6rem;
        }
      }

      @media screen and (max-width: 575px) {
        padding: 1rem;
      }
    }

    .devliver_box_body {
      padding: 4.2rem 2rem 2rem 2rem;

      p {
        font-size: 1.4rem;
        color: var(--greytext);

        @media screen and (max-width: 575px) {
          font-size: 1.4rem;
        }
      }

      @media screen and (max-width: 575px) {
        padding: 1.2rem;
      }
    }

    @media screen and (max-width: 575px) {
      min-height: auto;
      margin-right: 2rem;
    }
  }
}

// --------------- Why Algo Trading Section
.wat_section {
  width: 100%;

  p.desc {
    font-size: 1.6rem;
    margin-top: 1.5rem;

    @media screen and (max-width: 900px) {
      font-size: 1.4rem;
      margin-top: 2rem;
    }
  }

  .broker_list {
    width: 100%;
    height: 45rem;
    overflow: hidden;

    .slide_row {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
    }

    .broker_item {
      margin-right: 3rem;
      margin-bottom: 3rem;
      border-radius: 1.5rem;
      background-color: var(--whitetext);
      padding: 1rem;
      height: 11rem;
      max-width: calc(33.33% - 3rem);
      width: calc(33.33% - 3rem);
      display: inline-flex;
      align-items: center;
      justify-content: center;

      &:last-child {
        margin-right: 0;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }

      @media screen and (max-width: 1199px) {
        height: 9rem;
      }

      @media screen and (max-width: 767px) {
        margin-right: 1.5rem !important;
        margin-bottom: 1.5rem;
        height: 7rem;
        max-width: calc(33.33% - 1.5rem);
        width: calc(33.33% - 1.5rem);
        padding: 0;
      }

      @media screen and (max-width: 575px) {
        height: 5rem;
      }
    }

    @media screen and (max-width: 1199px) {
      height: 35rem;
    }

    @media screen and (max-width: 767px) {
      height: 25rem;
    }
  }

  &.client_section {
    .autoScroll {
      .marquee {
        .child {
          max-width: 52rem;
          margin-right: 3rem;
          padding-top: 10rem;

          @media screen and (max-width: 991px) {
            max-width: 40rem;
            margin-right: 2rem;
          }
        }
      }
    }

    .client_item {
      margin-right: 3rem;
      border-radius: 1.5rem;
      background-color: var(--boxcolor);
      padding: 1rem;
      height: 100%;
      position: relative;
      padding-top: 4.5rem;

      &:last-child {
        margin-right: 0;
      }

      .client_quotes {
        position: absolute;
        top: 3rem;
        right: 3rem;
      }

      .client_img {
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
        box-shadow: rgba(10, 83, 181, 0.3) 0px 20px 25px -5px,
          rgba(10, 83, 181, 0.3) 0px 10px 10px -5px;
        position: absolute;
        top: -50%;
        left: 50%;
        transform: translate(-50%, 50%);

        img {
          height: 100%;
          width: 100%;
        }

        @media screen and (max-width: 991px) {
          height: 8rem;
          width: 8rem;
        }
      }

      h4.client_name {
        font-size: 1.8rem;
        font-weight: 600;
        padding: 1.8rem 0;
        border-bottom: 1px solid rgba(61, 61, 61, 0.2);
        text-align: center;
        color: var(--colortitle);
      }

      p.client_desc {
        font-size: 1.4rem;
        font-weight: 500;
        margin-top: 2rem;
        color: var(--greytext);
        text-align: center;
      }

      @media screen and (max-width: 991px) {
        padding-top: 2rem;
      }
    }
  }
}

// --------------- Footer Section
footer.footer {
  background: var(--primarybglight);
  padding: 1rem 0;

  a.logo_footer {
    height: 6rem;
    width: 18rem;
    display: block;
    margin-bottom: 3rem;

    @media screen and (max-width: 991px) {
      height: 4rem;
      width: 12rem;
    }
  }

  p.desc_footer {
    font-size: 1.3rem;
    color: var(--greytext);
    padding-right: 4rem;

    @media screen and (max-width: 991px) {
      font-size: 1.4rem;
    }
  }

  h3.footer_mt {
    font-size: 1.8rem;
    color: var(--colortitle);
    margin-bottom: 1rem;
  }

  ul.footer_menu {
    list-style: none;

    li {
      margin-bottom: 1rem;

      &.mrbin {
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
      }

      &.nomb {
        margin-bottom: 0;
      }

      a.nav_link,
      p.nav_link {
        font-size: 1.3rem;
        color: #9d9d9d;
        padding-left: 0.8rem;

        &.active {
          color: #0a53b5;
        }

        @media screen and (max-width: 991px) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .social_profiles {
    height: calc(100% - 1rem);
    display: grid;
    align-content: space-between;

    button.app_links {
      background-color: transparent;
      height: 4.6rem;
      max-width: 14rem;
      margin-bottom: 1.5rem;
    }

    ul.social_links {
      display: flex;
      align-items: center;
      justify-content: left;
      list-style: none;
      margin-top: auto;

      li {
        margin-right: 1.5rem;

        a {
          height: 4rem;
          width: 4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.8rem;
          padding: 0.6rem;
          background-color: var(--boxcolor);

          img {
            height: 100%;
            width: 100%;
          }

          @media screen and (max-width: 991px) {
            height: 3.8rem;
            width: 3.8rem;
          }
        }
      }
    }

    @media screen and (max-width: 1199px) {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: end;
      justify-content: space-between;
    }

    @media screen and (max-width: 575px) {
      display: block;
    }
  }

  .footer_copyright {
    padding-top: 1rem;
    position: relative;
    margin-top: 0;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("./images/footer-line.png");
      height: 3px;
      width: 100%;
      background-size: 100%;
    }

    p {
      font-size: 1.3rem;
      color: var(--greytext);
    }
  }
}

.input_otp {
  text-align: center;
  padding: 1rem;
  width: 100%;
  height: auto !important;
  border-radius: 5px !important;
  border: 1px solid gray !important;
}

// --------------- Parter Form
.input_form {
  background-color: var(--boxcolor);
  padding: 2rem 4rem 4rem 4rem;
  border-radius: 2rem;

  .affiliate_item {
    margin-right: 2rem;
    width: calc(33.33% - 1.5rem);

    &:nth-child(3n + 3) {
      margin-right: 0;
    }

    @media screen and (max-width: 991px) {
      width: calc(50% - 1.5rem);

      &:nth-child(3n + 3) {
        margin-right: 2rem;
      }

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 575px) {
      width: 100%;
      margin: 0 !important;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 1rem;
  }
}

.getIn_touch {
  width: calc(33.33% - 3rem);
  margin-right: 3rem;
  padding: 3rem;
  background-color: var(--boxcolor);
  border-radius: 2rem;
  height: 100%;
  display: inline-block;

  &:last-child {
    margin-right: 0;
  }

  .getIn_touch_icon {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    background-image: var(--primarybg);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 2rem;
    padding: 1.2rem;

    svg {
      height: 100%;
      width: 100%;
    }
  }

  p {
    font-size: 1.6rem;
    color: var(--colortitle);
    text-align: center;
  }

  @media screen and (max-width: 1199px) {
    padding: 2rem;
  }

  @media screen and (max-width: 991px) {
    width: calc(50% - 2rem);
    margin-bottom: 2rem;
    margin-right: 2rem;
    height: 100%;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    margin-bottom: 2rem;
    margin-right: 0;
    height: 100%;
    padding: 1.5rem;
  }
}

.benefit {
  padding: 4rem;
  margin-top: 2rem;
  border-radius: 2rem;
  // background-color: var(--bodybg);
  background-color: var(--boxcolor);

  h3 {
    font-size: 2.8rem;
    color: var(--colortitle);
    margin: 1.5rem 0 1.5rem 0;
    font-weight: 500;
  }

  h4 {
    font-size: 2rem;
    color: var(--colortitle);
    margin: 1.5rem 0 1.5rem 0;
    font-weight: 500;
  }

  p {
    font-size: 1.4rem !important;
    color: var(--greytext);
    margin-bottom: 0.4rem;
  }
}

// ----------- Offer Modal
.offer_modal {
  .MuiPaper-root {
    max-height: 60rem !important;
    max-width: 60rem;
    width: 100%;

    .MuiDialogContent-root {
      overflow: hidden;
      padding: 1.5rem;
    }
  }
}

// ----------- term & Condition modal
.term_condition_modal {
  div.MuiPaper-elevation {
    max-width: 80rem;
    width: 100%;
    max-height: 90vh !important;

    h2.modal_header {
      font-size: 1.6rem;
      color: var(--colortitle);
    }

    h3 {
      font-size: 1.8rem;
      color: var(--colortitle);
      text-align: center;
      margin: 1rem;
    }

    h4 {
      font-size: 1.5rem;
      color: var(--colortitle);
      margin-top: 2rem;
    }

    p {
      font-size: 1.4rem;
      color: var(--greytext);
      margin-top: 2rem;

      &.subP {
        padding-left: 2rem;
      }

      span {
        font-weight: 600;
        font-size: 1.4rem;
        color: var(--colortitle);
        margin-right: 0.6rem;
      }
    }

    h5 {
      font-size: 1.8rem;
      color: var(--colortitle);
      margin-top: 2rem;
    }
  }
}

.OTP_verify_modal {
  div.MuiPaper-elevation {
    max-width: 50rem;
    width: 100%;

    .modal_header {
      font-size: 1.8rem;
      color: var(--colortitle);
    }

    .modal_body {
      h4 {
        font-size: 1.6rem;
        color: var(--primarytext);
        font-weight: 600;
        margin-bottom: 0.6rem;
      }

      p {
        font-size: 1.3rem;
        color: var(--greytext);
      }

      .otp_inputs {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        margin-top: 1.5rem;


        .otp_boxex {
          display: flex;
          align-items: center;
          justify-content: left;
          margin-top: 1.5rem;
        }

        .form_control {
          width: 4rem;
          max-width: 4rem;
          max-height: 4rem;
          height: 4rem;
          margin: 0.4rem;

          input {
            text-align: center;
          }
        }
      }
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #FF3D00;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}

.sample-btn-csv{
  margin-left:10px !important;
}

#checkoutmodal.hide-checkout{
  display: none !important;
}

.modalpage.hide-checkout{
  display: none !important;
}