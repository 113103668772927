.wrapper-1 {
    width: 70%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .wrapper-3 {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .wrapper-2 {
    padding: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h1 {
    font-family: "Kaushan Script", cursive;
    font-size: 4em;
    letter-spacing: 3px;
    color: #1e1e1e;
    margin: 0;
    //   margin-bottom: 20px;
  }
  .wrapper-2 p {
    margin: 0;
    font-size: 1.3em;
    color: #1e1e1e;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 1px;
  }

  .go-home {
    cursor: pointer;
    color: #fff;
    background: #26a1f4;
    border: none;
    padding: 10px 50px;
    margin: 0;
    border-radius: 30px;
    text-transform: capitalize;
    box-shadow: 0 0 0px 0px rgba(174, 199, 251, 1);
  }

  .go-home-fail {
    cursor: pointer;
    color: #fff;
    background: #ff4141;
    border: none;
    padding: 10px 50px;
    margin: 30px 0;
    border-radius: 30px;
    text-transform: capitalize;
    box-shadow: 0 0 0px 0px rgba(174, 199, 251, 1);
  }
  // .footer-like {
  //   margin-top: auto;
  //   background: #d7e6fe;
  //   padding: 6px;
  //   text-align: center;
  // }
  // .footer-like p {
  //   margin: 0;
  //   padding: 4px;
  //   color: #203a82;
  //   font-family: "Source Sans Pro", sans-serif;
  //   letter-spacing: 1px;
  // }
  // .footer-like p a {
  //   text-decoration: none;
  //   color: #203a82;
  //   font-weight: 600;
  // }

  @media (min-width: 360px) {
    h1 {
      font-size: 2.5em;
      letter-spacing: 1px;
      margin: 5px 0;
    }
    .go-home {
      margin-bottom: 20px;
      cursor: pointer;
    }
    .go-home-fail {
      margin-bottom: 20px;
      cursor: pointer;
    }
  }

  @media (min-width: 600px) {
      .content {
        margin: 150px auto;
      }
    .wrapper-1 {
      height: initial;
      margin: 0 auto;
      margin-top: 50px;
      box-shadow: 0px 1px 10px 0px rgba(160, 161, 161, 0.8);
    }
    .wrapper-3 {
      height: initial;
      margin: 0 auto;
      margin-top: 50px;
      box-shadow: 0px 1px 10px 0px rgba(160, 161, 161, 0.8);
    }
  }

  .date-payment {
    font-size: 13px !important;
    font-weight: 700;
    color: #454444 !important;
  }

  .paymentDetails {
    width: 350px;

    ul {
      padding: 0 0 0 10px;
    }
  }
  .listItem {
    padding: 10px 0;
    text-align: start;
    width: 100%;

    .heading {
      font-weight: 600 !important;
      font-size: 1.1em;
    }

    .value {
      color: #2e2e2e;
    }
  }
