@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


.close_icon_csv{
  cursor:pointer;
  color: #fff;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-right: 16px;
  margin-top:15px;
  font-size:22px;
  color: var(--blacktext);
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed #1475cf;
  height: 100%;
  min-height: 300px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
}

.main-upload-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  padding:20px;
  padding-top:10px;
}

.uploaded-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #e9f0ff;
}

.upload-content {
  display: flex;
  align-items: center;
}

.form-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-files-container {
  width: 420px;
  padding: 30px 60px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-file-area {
  border: 2px dashed #7b2cbf;
  border-radius: 40px;
  margin: 10px 0 15px;
  padding: 30px 50px;
  width: 350px;
  text-align: center;
}

.upload-icon {
  margin-bottom: 10px;
  color: var(--primarytext) !important
}

.drag-file-area h3 {
  font-size: 26px;
  margin: 15px 0;
}

.drag-file-area label {
  font-size: 19px;
}

.browse-files-text {
  color: #7b2cbf;
  font-weight: bolder;
  cursor: pointer;
}

.browse-files span {
  position: relative;
  top: -25px;
}

.cannot-upload-message {
  background-color: #ffc6c4;
  font-size: 17px;
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 5px 10px 5px 30px;
  border-radius: 5px;
  color: #BB0000;
  display: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.cannot-upload-message span,
.upload-button-icon {
  padding-right: 10px;
}

.cannot-upload-message span:last-child {
  padding-left: 20px;
  cursor: pointer;
}

.file-block {
  color: #f7fff7;
  background-color: #7b2cbf;
  transition: all 1s;
  width: 390px;
  position: relative;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 15px;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
  font-size: 15px;
}

.file-icon {
  margin-right: 10px;
}

.file-name,
.file-size {
  padding: 0 3px;
}

.remove-file-icon {
  cursor: pointer;
}

.progress-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 4.5%;
  width: 0;
  height: 5px;
  border-radius: 25px;
  background-color: #4BB543;
}

.dark-color-text{
  color: var(--darktext) !important;
}

.upload-button {
  font-family: 'Montserrat' !important;
  background-color: var(--primarytext) !important;
  color: #f7fff7 !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  border: none !important;
  border-radius: 20px !important;
  margin: 10px !important;
  padding: 5px 25px !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
}

* {
  margin: 0;
  padding: 0;
}

.dialogBox {
  div.MuiPaper-elevation {
    max-width: 40rem;
    min-height: 50rem;
    width: 100%;
    height: 100%;
    overflow: auto;
    border: none;
  }
}

.benefit ul {
  list-style-type: disc;
  margin-left: 19px;

  li {
    // color: var(--colortitle);
    color: #65a6fc;
    font-size: 1.4rem;
    margin-bottom: 0.4rem;

  }
}

.blue-style {
  font-size: 1.4rem !important;
  color: #65a6fc !important;
  // color: var(--colortitle) !important;
  // font-weight: 600 !important;
}

.benefit .charges-style {
  font-size: 1.6rem !important;
}

.span-style {
  font-weight: bold !important;
  font-size: 1.4rem !important;
  color: var(--colortitle) !important;
}

.benefit .disclaimer-style {
  font-size: 1.2rem !important;
}

.payment_success_card.loading {
  padding: 20px 0;
}


.logo_wrapper.loading {
  margin-top: 20px;
}

.logo_div.loading {
  padding: 25px 0px;
}

.loading_content {
  .img {
    margin: 10px 0;
  }
}

.logo_wrapper {
  width: 100px;
  height: 100px;
  background: rgb(255, 255, 255);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;


  img {
    width: 100%;
  }
}

p.planType.company_name {
  margin: 13px 0;
  font-weight: 800;
  font-size: 1em;

}

.planType.company_name.loading {
  color: var(--colortitle) !important;
}

.loading_content {
  padding: 0 20px;
}


.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    margin: 8px;
    border-radius: 50%;
    border: 3px solid;
    border-color: var(--primarytext) transparent var(--primarytext) transparent;
    animation: lds-dual-ring 1s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


.planCard_close_icon {
  display: flex;
  justify-content: end;
  cursor: pointer;
}


.payment_success_card {
  background: var(--bodybg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: 100%;


  .payment_icon {
    padding: 10px;
    border-radius: 50%;
    color: #fff;
    background: green;
    font-size: 2rem;
  }

}

.payment_title {
  font-size: 1.5rem !important;
  margin-top: 7px !important;
  color: var(--colortitle) !important;
}

.dropdown-ap {
  .dropdown {
    min-width: 100%;
    margin: 0;
    height: 100%;
    padding: 0 1rem;
    position: relative;

    .MuiSelect-outlined {
      display: block;
      align-items: center;
      width: 100%;
      padding: 0 1.4rem 0 0 !important;
      font-size: 1.3rem !important;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;

      @media screen and (max-width: 991px) {
        height: 100%;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        display: flex;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
      border: 1px solid var(--color-primary) !important;
      background-color: var(--color-lightsuccess);
    }

    .MuiSelect-icon {
      right: 2px;
      font-size: 2.4rem;
    }

    &.Mui-select2 {
      .MuiSelect-select {
        width: 100% !important;

        .MuiBox-root {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      display: inline-block !important;
      width: 100%;
      height: 3.2rem;
      padding: 0.5rem !important;
    }

    &.icon_set {
      position: relative;

      svg.MuiSvgIcon-root {
        display: none;
      }

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0.6rem;
        transform: translateY(-50%);
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='AccessTimeRoundedIcon'%3E%3Cpath d='M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm-.22-13h-.06c-.4 0-.72.32-.72.72v4.72c0 .35.18.68.49.86l4.15 2.49c.34.2.78.1.98-.24.21-.34.1-.79-.25-.99l-3.87-2.3V7.72c0-.4-.32-.72-.72-.72z'%3E%3C/path%3E%3C/svg%3E");
        height: 2rem;
        width: 2rem;
        background-repeat: no-repeat;
      }
    }
  }

  .MuiButtonBase-root:hover,
  .MuiButtonBase-root.Mui-selected {
    background-color: var(--color-lightPrimary) !important;
  }

  @media screen and (max-width: 1199px) {
    display: inline-block !important;
    width: 100%;
  }
}

.MuiAutocomplete-hasPopupIcon.css-163wblo-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0px !important;
}

div.planCard {
  height: 100%;
  border: var(--border);
  border-radius: 0.5rem;
  // margin-top: 2rem;
  // margin-left: 1.6rem;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  &:hover {
    border-color: var(--color-blue);
    border-width: 2px;
  }

  div.planCard-type {
    // min-height: 7rem;
    width: 100%;
    // border-bottom: 1px solid var(--greytext);
    // padding: 2rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;



    p.planType {
      font-size: 2rem;
      color: var(--color-dark);
      text-align: center;
    }



    .planPrice {
      display: flex;
      align-items: end;
      justify-content: center;

      span {
        &:first-child {
          font-size: 3.5rem;
          color: var(--color-darkgrey);
        }

        &:nth-child(2) {
          font-size: 3.5rem;
          color: var(--color-dark);
          font-weight: 600;
        }

        &:last-child {
          font-size: 1.4rem;
          color: var(--color-darkgrey);
          margin-bottom: 1rem;
        }
      }
    }

    .save {
      font-size: 1.4rem;
      color: var(--color-darkgrey);
      text-align: center;

      span {
        font-size: 1.4rem;
        color: var(--color-blue);
        font-weight: 500;
        margin-right: 0.5rem;
        display: block;
        width: 100%;

        @media screen and (max-width: 1399px) {
          display: block;
          text-align: center;
        }
      }

      del {
        color: var(--color-white);
        background-color: var(--color-blue);
        padding: 0.4rem 1rem;
        border-radius: 5rem;
        display: inline-block;
        margin-left: 0.5rem;
        font-size: 1.2rem;

        @media screen and (max-width: 1399px) {
          display: inline-block;
          text-align: center;
        }
      }

      @media screen and (max-width: 1399px) {
        display: block;
        text-align: center;
      }
    }

    @media screen and (max-width: 991px) {
      height: auto;
    }
  }



  div.planCard-content {
    // padding: 2rem 3rem;
    // border-top: var(--border);
    // height: 30rem;
    display: grid;
    align-content: space-between;

    ul {
      padding: 0;

      li {
        margin-bottom: 0.6rem;
        position: relative;
        padding-left: 2.6rem;
        font-size: 1.3rem;
        color: var(--color-light);
        font-weight: 400 !important;
        display: flex;
        align-items: center;
        justify-content: left;
        word-break: break-all;

        &:after {
          content: "";
          position: absolute;
          top: 1rem;
          left: 0;
          background-image: url("../../src/images/check.png");
          height: 1.6rem;
          width: 1.6rem;
          background-size: 100%;
        }
      }
    }

    button.planCard-btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-light);
      color: var(--color-darkgrey);
      font-size: 1.4rem;
      border: var(--border);
      margin-top: 1rem;

      &:hover {
        background-color: var(--color-blue);
        color: var(--color-white) !important;
      }

      svg {
        margin-right: 1rem;
      }
    }

    @media screen and (max-width: 1199px) {
      height: auto;
    }
  }

  div.planCard-footer {
    .payment_btn {
      margin: 0;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &.premium {
    background-color: var(--color-darkblue);
    border-color: var(--color-darkblue);

    * {
      color: #ffffff !important;
    }

    .save {
      span {
        color: #f39a00 !important;
        margin-right: 0.5rem;
      }

      del {
        background-color: #f39a00 !important;
        margin-left: 0.5rem;
      }
    }

    button.planCard-btn {
      background-color: var(--color-blue) !important;
      color: var(--color-white) !important;
    }
  }

  @media screen and (max-width: 1299px) {
    width: 100%;
  }

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;
    margin-left: 0;
  }


  @media screen and (max-width: 1299px) {
    flex-wrap: wrap;
  }
}

.logo_div {
  padding: 20px 0px;
}

.MuiAutocomplete-popper {
  overflow: hidden;

  ul {
    background-color: var(--whitetext) !important;
    padding: 0 !important;
    margin: 0 !important;
    overflow-y: auto;
    max-height: 30rem;
  }

  * {
    font-size: 1.4rem !important;
    padding: 0 !important;
    box-shadow: none !important;

    li {
      padding: 0.6rem 0.6rem !important;
    }
  }
}