.signin_form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8rem 0 4rem 0;

  .signin_form_left,
  .signin_form_right {
    width: 50%;
    max-width: 50%;
    height: auto;

    @media screen and (max-width: 991px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .signin_form_left {
    max-height: 80rem;
    height: 80rem;
    margin-left: 2rem;
    max-width: calc(50% - 2rem);
    width: calc(50% - 2rem);

    img {
      border-radius: 1.6rem;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .signin_form_right {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem;

    .sign_form_element {
      background-color: var(--boxcolor);
      padding: 3.5rem;
      border-radius: 2rem;
      max-width: 60rem;
      width: 100%;
      max-height: 55rem;
      height: 55rem;
      display: grid;
      align-content: center;

      &.fullHeight {
        max-height: 100%;
        height: 100%;
      }

      .sign_form_logo {
        width: 14rem;
        height: 5rem;
        margin-bottom: 3rem;
      }

      .welcome_tagLine {
        h5 {
          font-size: 2.2rem;
          color: var(--colortitle);
        }

        p {
          font-size: 1.6rem;
          color: var(--greytext);

          &.filled {
            color: var(--primarytext) !important;
          }
        }
      }

      .mobile_to_login {
        h3 {
          font-size: 2.6rem;
          color: var(--colortitle);
          margin-top: 2rem;
          margin-bottom: 1rem;
        }
      }

      .otp_send_success {
        padding: 2rem;
        border-radius: 2rem;
        background-color: rgba($color: #77a9eb, $alpha: 0.1);
        margin-bottom: 3rem;
        display: flex;
        align-items: flex-start;

        .otp_content {
          width: calc(100% - 2rem);
          margin-left: 2rem;

          p {
            font-size: 1.6rem;
            color: var(--greytext);

            @media screen and (max-width: 991px) {
              font-size: 1.4rem;
            }
          }
        }
      }

      .resend_otp {
        position: relative;

        .actions_otp {
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translateY(-50%);
          z-index: 2;

          button.otp_viewHide {
            color: var(--primarytext);
            font-size: 1.4rem;
            font-weight: 600;
            background-color: transparent;
            min-width: 3rem;
          }

          button.resend_otp_btn {
            color: var(--primarytext);
            font-size: 1.4rem;
            font-weight: 600;
            background-color: transparent;
          }
        }
      }

      p.valide_otp {
        font-size: 1.4rem;
        color: var(--greytext);
        margin-top: 0.4rem;

        span {
          font-size: 1.4rem;
          color: #e34c4c;
          font-weight: 600;
          margin: 0 0.4rem;
        }
      }

      @media screen and (max-width: 991px) {
        max-width: 60rem;
        width: 100%;
      }

      @media screen and (max-width: 575px) {
        padding: 1.5rem;
        max-width: 100%;
      }
    }

    @media screen and (max-width: 767px) {
      padding: 1.5rem;
      max-width: 100%;
    }
  }
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding-bottom: 0 !important;
  margin-right: 0.8rem;

  .ReactFlagsSelect-module_selectBtn__19wW7 {
    padding: 0.5rem;
    border: 1px solid var(--inputborder);
    background-color: var(--inputbg);

    span.ReactFlagsSelect-module_selectValue__152eS {
      padding: 0;
      font-size: 1.3rem;
    }
  }

  ul {
    max-width: 11rem;
    min-width: 11rem;

    li {
      span {
        font-size: 1.4rem;
      }
    }
  }
}

// ----------- term & Condition modal
.term_condition_modal {
  div.MuiPaper-elevation {
    max-width: 80rem;
    width: 100%;
    max-height: 90vh !important;

    h2.modal_header {
      font-size: 1.6rem;
      color: var(--colortitle);
    }

    h3 {
      font-size: 1.8rem;
      color: var(--colortitle);
      text-align: center;
      margin: 1rem;
    }

    h4 {
      font-size: 1.8rem;
      color: var(--color-dark);
      margin-top: 2rem;
    }

    p {
      font-size: 1.3rem;
      color: var(--colortitle);
      margin-top: 1.5rem;
      opacity: 0.9;

      a {
        font-size: 1.3rem;
        color: var(--color-primary);
        text-decoration: underline !important;
      }
    }

    h5 {
      font-size: 1.8rem;
      color: var(--colortitle);
      margin-top: 2rem;
    }
  }
}

.accoutnHere {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  p {
    font-size: 1.4rem;
    color: var(--darktext)
  }

  a.signIn_go {
    font-size: 1.4rem;
    color: var(--primarytext) !important;
    font-weight: 500;
  }
}
