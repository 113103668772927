// --------------- Help Center

.help_center {
  position: fixed;
  bottom: 1rem;
  right: 1.5rem;
  z-index: 45;

  button.help_button {
    height: 4.5rem;
    width: 4.5rem;
    min-width: 4.5rem;
    border-radius: 50%;
    background: var(--primarybg);
    margin-top: 1rem;

    &.outlined {
      border: 1px solid var(--primarytext);
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: transparent;
    }
  }

  .help_center_box {
    max-width: 44rem;
    width: 44rem;
    height: auto;
    position: absolute;
    bottom: 12rem;
    right: 0;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 2rem;
    border-radius: 2rem;
    background-color: var(--boxcolor);
    border: 1px solid var(--inputborder);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 990;

    .help_center_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2.5rem;
      position: relative;

      &::before {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        background-image: url('../images/footer-line.png');
        background-size: 100%;
        width: 100%;
        height: 0.6rem;
        background-position: center;
        background-repeat: no-repeat;
      }

      h5 {
        font-size: 2rem;
        color: var(--colortitle);
      }

      button.close_help_center {
        height: 4rem;
        width: 4rem;
        min-width: 4rem;
        border-radius: 50%;
        background-color: var(--inputbg);
        color: var(--colortitle);
      }
    }

    p.help_tag_line {
      font-size: 1.6rem;
      text-align: center;
      color: var(--greytext);
      padding: 0 3rem;
      margin-top: 2.5rem;
    }

    @media screen and (max-width:991px) {
      bottom: 10rem;
    }

    @media screen and (max-width:767px) {
      max-width: calc(100vw - 3rem);
      width: calc(100vw - 3rem);
      margin: 0;
      bottom: 0;
    }
  }
}

a.wp_button {
  height: 4.5rem;
  width: 4.5rem;
  display: block;
  margin-top: 1rem;

  img {
    height: 100%;
    width: 100%;
  }
}