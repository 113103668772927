html[data-theme="light"] {
  --primarybg: linear-gradient(135deg, #166bdd 0%, #003d8f 100%);
  --primarydisabled: linear-gradient(135deg, #65a6fc 0%, #315b91 100%);
  --disabled-text:rgba(0, 0, 0, 0.5);
  --color-darkblue: #18214d;
  --primarytext: #0a53b5;
  --greytext: #9d9d9d;
  --color-blue: #203a82;
  --whitetext: #ffffff;
  --blacktext: #000000;
  --darktext: #333333;
  --bodybg: #f8fbff;
  --inputbg: #f2f2f2;
  --inputborder: #dddddd;
  --colortitle: #333333;
  --boxcolor: #ffffff;
  --herosection: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 50%
  );
  --color-light: #f2f2f2;
  --primarybglight: linear-gradient(
    135deg,
    rgba(22, 107, 221, 10%) 0%,
    rgba(0, 61, 143, 10%)
  );

  --toastify-color-success: #0a53b5;
  --toastify-color-error: #ff0000;
}

html[data-theme="dark"] {
  --primarybg: linear-gradient(135deg, #166bdd 0%, #003d8f 100%);
  --primarydisabled: linear-gradient(135deg, #65a6fc 0%, #315b91 100%);
  --disabled-text: rgba(255, 255, 255, 0.5);
  --primarytext: #0a53b5;
  --greytext: #9d9d9d;
  --whitetext: #ffffff;
  --blacktext: #ffffff;
  --darktext: #333333;
  --bodybg: #060505;
  --color-blue: #203a82;
  --colortitle: #ffffff;
  --boxcolor: #000000;
  --color-darkblue: #ffffff;
  --herosection: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  --color-light: #f2f2f2;
  --inputbg: #131212;
  --inputborder: #272727;
  --primarybglight: rgba(255, 255, 255, 10%);
  body {
    .premium {
      background-color: #18314d !important;
      border-color: #18214d !important;

      li {
        color: var(--color-white) !important;
      }
    }
  }
}
